import { validateField, validateEmail, validatePassword } from '../../core/utils/validation';

import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { VALIDATION_ERRORS, ROLE_KEYS, DASHBOARDS_MAP } from '../../core/constants';

import { connect } from 'react-redux';
import { isObject } from 'lodash';

import { Button, TextField, InputAdornment } from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import { AuthFormWrapper } from '../../components/Auth/AuthFormWrapper';

import { withTranslation } from '../../i18n';
import { compose } from 'recompose';

import { Router, Link } from '../../server/routes';
import api from '../../core/api';
import socket from '../../core/ws';
import actions from '../../core/store/actions/auth';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      errors: {
        email: null,
        password: null,
      },
      showPwd: false,
    };
  }

  handleKeyPress = ({ key }) => {
    if (key === 'Enter') {
      this.handleLogin();
    }
  };

  handleInputChange = name => ev => {
    const {
      target: { value },
    } = ev;

    this.setState({
      [name]: value,
      errors: {
        ...this.state.errors,
        [name]: null,
      },
    });
  };

  handleInputBlur = name => () => {
    const value = this.state[name];
    const error = validateField(name, value);
    if (error) {
      this.setState({
        errors: {
          [name]: error,
        },
      });
    }
  };

  handleLogin = async () => {
    const { email, password } = this.state;
    const { dispatch, t } = this.props;
    if (!email.length || !password.length) return;
    const emailError = validateEmail(email);
    const pwdError = validatePassword(password);
    if (emailError || pwdError) {
      this.setState({
        errors: {
          ...(emailError && { email: emailError }),
          ...(pwdError && { password: pwdError }),
        },
      });
      return;
    }

    try {
      const { user } = await api.Auth.login({
        email,
        password,
      });
      if (user) {
        const {
          role: { code },
        } = user;
        dispatch(actions.setCurrentUser(user));

        if (user?.role?.code && user?.role?.code !== ROLE_KEYS.USER) {
          socket.emit('connected_agent', user);
        }

        Router.pushRoute(DASHBOARDS_MAP[code]);
      } else {
        this.setState({
          errors: {
            email: t(VALIDATION_ERRORS.UNAUTHORIZED),
            password: t(VALIDATION_ERRORS.UNAUTHORIZED),
          },
        });
      }
    } catch (err) {
      const errors = isObject(err?.error)
        ? {
            email: t(err.error?.email || ''),
            password: t(err.error?.password || ''),
          }
        : {
            email: t(VALIDATION_ERRORS.UNAUTHORIZED),
            password: t(VALIDATION_ERRORS.UNAUTHORIZED),
          };

      this.setState({
        errors,
      });
    }
  };

  toggleShowPwd = () => {
    this.setState({
      showPwd: !this.state.showPwd,
    });
  };

  render() {
    const { email, password, errors, showPwd } = this.state;

    const { t } = this.props;

    return (
      <AuthFormWrapper>
        <TextField
          sx={{ marginBottom: '10px' }}
          id="email"
          name="mail"
          error={errors?.email}
          helperText={errors?.email ? t(errors.email) : ' '}
          FormHelperTextProps={{
            sx: { fontSize: '11px' },
          }}
          label={t('pages.auth.email_input.placeholder')}
          value={email}
          inputProps={{
            sx: { fontWeight: '400' },
          }}
          InputLabelProps={{
            sx: { fontWeight: '400' },
          }}
          fullWidth
          variant="outlined"
          onChange={this.handleInputChange('email')}
          onBlur={this.handleInputBlur('email')}
          onKeyPress={this.handleKeyPress}
        />

        <TextField
          sx={{ marginBottom: '10px' }}
          id="password"
          name="password"
          error={errors?.password}
          helperText={errors?.password ? t(errors.password) : ' '}
          FormHelperTextProps={{
            sx: { fontSize: '11px' },
          }}
          value={password}
          type={showPwd ? 'text' : 'password'}
          label={t('pages.auth.pwd_input.placeholder')}
          inputProps={{
            sx: { fontWeight: '400' },
          }}
          InputLabelProps={{
            sx: { fontWeight: '400' },
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment
                sx={{ cursor: 'pointer' }}
                onClick={this.toggleShowPwd}
                position="end"
              >
                {showPwd ? <VisibilityOffIcon /> : <VisibilityIcon />}
              </InputAdornment>
            ),
          }}
          fullWidth
          variant="outlined"
          onChange={this.handleInputChange('password')}
          onBlur={this.handleInputBlur('password')}
          onKeyPress={this.handleKeyPress}
        />

        <Button
          sx={{ textTransform: 'none', marginBottom: '10px' }}
          fullWidth
          variant="contained"
          onClick={this.handleLogin}
        >
          {t('pages.auth.login_btn_label')}
        </Button>

        <Link route="/auth/recovery">
          <Button fullWidth>{t('pages.auth.forgot_pwd_label')}</Button>
        </Link>
      </AuthFormWrapper>
    );
  }
}

Login.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default compose(
  withTranslation('common'),
  connect(({ auth }, ownProps) => ({
    ...ownProps,
    auth,
  })),
)(Login);
